$(document).on("turbolinks:load", () => {
  if (
    $("body").hasClass("developments") &&
    $("body").hasClass("show") &&
    $("body").hasClass("public")
  ) {
    // Lead form & modal
    const $leadModal = $(".modal#lead-modal");
    const $leadModalForm = $leadModal.find("form.lead-form");
    const $leadModalFormBtn = $leadModalForm.find(".btn-submit");
    const $leadModalTitle = $leadModal.find(".modal-title");
    const $leadModalRequest = $leadModalForm.find("[name='lead[request]']");

    $leadModalForm.validate({
      ignoreTitle: true,
      rules: {
        "lead[civility]": { required: true },
        "lead[first_name]": { required: true },
        "lead[last_name]": { required: true },
        "lead[email]": { required: true, email: true },
        "lead[phone_number]": { required: true },
      },
      messages: {
        "lead[civility]": {
          required: "Veuillez saisir votre civilité",
        },
        "lead[first_name]": {
          required: "Veuillez saisir votre prénom",
        },
        "lead[last_name]": {
          required: "Veuillez saisir votre nom",
        },
        "lead[email]": {
          required: "Veuillez saisir votre e-mail",
          email: "E-mail non valide",
        },
        "lead[phone_number]": {
          required: "Veuillez saisir votre téléphone",
        },
      },
      errorElement: "label",
      errorPlacement: (error, element) => {
        var fg = $(element).closest(".form-group");
        $(fg).addClass("has-error");
        $(fg).append(error);
      },
      success: (label, element) => {
        var fg = $(element).closest(".form-group");
        $(fg).removeClass("has-error");
      },
      submitHandler: (form) => {
        $leadModalFormBtn.addClass("loading").attr("disabled", true);

        const leadData = {
          request: $leadModalRequest.val(),
          leadable_pid: developmentPID,
          civility: $leadModalForm
            .find("[name='lead[civility]']:checked")
            .val(),
          first_name: $leadModalForm.find("[name='lead[first_name]']").val(),
          last_name: $leadModalForm.find("[name='lead[last_name]']").val(),
          email: $leadModalForm.find("[name='lead[email]']").val(),
          phone_number: $leadModalForm
            .find("[name='lead[phone_number]']")
            .val(),
          message: $leadModalForm.find("[name='lead[message]']").val(),
          ssb: $leadModalForm.find("[name='lead[ssb]']").val(),
        };

        $.ajax({
          url: "/leads",
          type: "POST",
          data: { lead: leadData },
          dataType: "json",
          success: (data, status, xhr) => {
            $leadModal.find(".step--message .content").html(successTpl());
            setTimeout(() => {
              $leadModalFormBtn.removeClass("loading").attr("disabled", false);
              $leadModal.find(".step--form").hide();
              $leadModal.find(".step--message").show();
            }, 250);
          },
          error: (res) => {
            $leadModal.find(".step--message .content").html(errorTpl());
            setTimeout(() => {
              $leadModalFormBtn.removeClass("loading").attr("disabled", false);
              $leadModal.find(".step--form").hide();
              $leadModal.find(".step--message").show();
            }, 250);
          },
        });
      },
      invalidHandler: (event, validator) => {
        $leadModalFormBtn.removeClass("loading").attr("disabled", false);
      },
    });

    $leadModalForm.removeClass("d-none");

    const successTpl = () => {
      return `
        <div class="icon">
          <i class="mdi mdi-check-circle text-success"></i>
        </div>
  
        <h3 class="title">Demande envoyée</h3>
  
        <p class="msg">
          Votre demande a été envoyée avec succès. Un conseiller immobilier vous recontactera rapidement.
        </p>
  
        <div class="close-wrapper">
          <button class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
        </div>
      `;
    };

    const errorTpl = () => {
      return `
        <div class="icon">
          <i class="mdi mdi-alert-circle text-danger"></i>
        </div>
  
        <h3 class="title">Une erreur est survenue!</h3>
  
        <p class="text">
          Votre demande n'a pas été envoyée car une erreur est survenue sur nos serveurs. Veuillez essayer de nouveau. Contactez-nous si cette erreur persiste.
        </p>
  
        <div class="close-wrapper">
          <button class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
        </div>
      `;
    };

    const cleanLeadModal = () => {
      $leadModal.find(".step--form").show();
      $leadModal.find(".step--message").hide();
      $leadModal.find(".step--message .content").html("");

      $leadModalRequest.val("");
      $leadModalTitle.html("");
      $leadModalForm.find("[name='lead[message]']").val("");

      $leadModalForm.find(".form-group.has-error").removeClass("has-error");
      $leadModalForm.find(".form-control.error").removeClass("error");
      $leadModalForm.find(".form-group label.error").remove();

      $leadModalFormBtn.removeClass("loading").attr("disabled", false);
    };

    $leadModal.on("hidden.bs.modal", (e) => {
      cleanLeadModal();
    });

    // Stock
    $(".section-stock .detailsBtn").on("click", (e) => {
      e.preventDefault();
      const unitID = $(e.currentTarget).data("uid");
      $leadModalTitle.html(`Télécharger le plan du lot ${unitID}`);
      $leadModalRequest.val(`Télécharger le plan du lot ${unitID}`);
      $leadModal.modal("show");
    });

    // Brochure
    $(".brochureBtn").on("click", (e) => {
      e.preventDefault();
      $leadModalTitle.html("Recevoir la brochure");
      $leadModalRequest.val("Recevoir la brochure");
      $leadModal.modal("show");
    });

    // Callback
    $(".callbackBtn").on("click", (e) => {
      e.preventDefault();
      $leadModalTitle.html("Être rappelé");
      $leadModalRequest.val("Être rappelé");
      $leadModal.modal("show");
    });
  }
});
