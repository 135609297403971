import lightGallery from "lightgallery";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import mapboxgl from "!mapbox-gl";

$(document).on("turbolinks:load", () => {
  if ($("body").hasClass("developments") && $("body").hasClass("show")) {
    let mapMarker = null;

    const buildImagesLGallery = () => {
      lightGallery(document.getElementById("lg-images"), {
        plugins: [lgZoom, lgThumbnail],
        mode: "lg-slide",
        speed: 500,
        loop: false,
        slideEndAnimation: false,
      });

      const openImagesLGallery = () => {
        $("#lg-images img:first").trigger("click");
      };

      $(".section-cover .cover-wrapper").on("click", (event) => {
        event.preventDefault();
        openImagesLGallery();
      });
    };

    const initMap = () => {
      mapboxgl.accessToken =
        "pk.eyJ1IjoiYWxkaW1tbyIsImEiOiJjamh2cTVxcm4wd3Q2M3Jxa3N0dDMxbThkIn0.rrCseKZtVNHzJLRUBNXy3w";

      window.map = new mapboxgl.Map({
        container: "map",
        style: "mapbox://styles/mapbox/streets-v11",
        center: [devLng, devLat],
        zoom: 15,
        interactive: true,
        scrollZoom: false,
      });

      var nav = new mapboxgl.NavigationControl();
      map.addControl(nav, "top-right");

      var ll = new mapboxgl.LngLat(devLng, devLat);
      mapMarker = new mapboxgl.Marker().setLngLat(ll).addTo(map);

      map.on("load", (e) => {
        map.resize();
      });
    };

    buildImagesLGallery();
    initMap();
  }
});
