$(document).on("turbolinks:load", () => {
  if ($("body").hasClass("contact") && $("body").hasClass("index")) {
    const $contactForm = $("form.contact-form");
    const $contactFormSubmitBtn = $contactForm.find(".btn-submit");

    const buildContactForm = () => {
      $contactForm.validate({
        ignoreTitle: true,
        rules: {
          "message[first_name]": { required: true },
          "message[last_name]": { required: true },
          "message[email]": { required: true, email: true },
          "message[body]": { required: true },
        },
        messages: {
          "message[first_name]": {
            required: "Veuillez saisir votre prénom",
          },
          "message[last_name]": {
            required: "Veuillez saisir votre nom",
          },
          "message[email]": {
            required: "Veuillez saisir votre e-mail",
            email: "E-mail non valide",
          },
          "message[body]": {
            required: "Veuillez saisir votre message",
          },
        },
        errorElement: "label",
        errorPlacement: (error, element) => {
          var fg = $(element).closest(".form-group");
          $(fg).addClass("has-error");
          $(fg).append(error);
        },
        success: (label, element) => {
          var fg = $(element).closest(".form-group");
          $(fg).removeClass("has-error");
        },
        submitHandler: (form) => {
          $contactForm.find(".error-msg").html("");
          $contactFormSubmitBtn.addClass("loading").attr("disabled", true);
          form.submit();
        },
        invalidHandler: (event, validator) => {
          $contactFormSubmitBtn.removeClass("loading").attr("disabled", false);
        },
      });
      $contactForm.removeClass("d-none");
    };

    buildContactForm();

    if (messageSent == "true") {
      bootbox.alert({
        className: "bootbox-modal",
        centerVertical: true,
        backdrop: true,
        message: `<div class="icon"><i class="mdi mdi-check-circle text-success"></i></div>
                  <h3 class="title">Message envoyé !</h3>
                  <p class="text mb-1">Nous avons bien reçu votre message.<br>Nous vous contacterons dans les plus brefs délais.</p>`,
        closeButton: false,
        buttons: {
          ok: {
            label: "OK",
            className: "btn-secondary px-5",
          },
        },
      });
    }
  }
});
