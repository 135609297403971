$(document).on("turbolinks:load", () => {
  if ($("body").hasClass("pages") && $("body").hasClass("home")) {
    const $subscriptionForm = $("form.subscription-form");
    const $subscriptionFormSubmitBtn = $subscriptionForm.find(".btn-submit");

    const cleanSubscriptionForm = () => {
      $subscriptionForm.find("[name='subscription[email]']").val("");
      $subscriptionForm.find(".form-group.has-error").removeClass("has-error");
      $subscriptionForm.find(".form-control.error").removeClass("error");
      $subscriptionForm.find("label.error").remove();
    };

    const buildSubscriptionForm = () => {
      $subscriptionForm.validate({
        ignoreTitle: true,
        rules: {
          "subscription[email]": { required: true, email: true },
        },
        messages: {
          "subscription[email]": {
            required: "Veuillez saisir votre e-mail",
            email: "E-mail non valide",
          },
        },
        errorElement: "label",
        errorPlacement: (error, element) => {
          var fg = $(element).closest(".form-group");
          $(fg).addClass("has-error");
          $(fg).append(error);
        },
        success: (label, element) => {
          var fg = $(element).closest(".form-group");
          $(fg).removeClass("has-error");
        },
        submitHandler: (form, event) => {
          $subscriptionFormSubmitBtn.addClass("loading").attr("disabled", true);

          const subData = {
            email: $subscriptionForm.find("[name='subscription[email]']").val(),
          };

          $.ajax({
            url: "/newsletter",
            type: "POST",
            data: { subscription: subData },
            dataType: "json",
            success: (data, status, xhr) => {
              $subscriptionFormSubmitBtn
                .removeClass("loading")
                .attr("disabled", false);

              cleanSubscriptionForm();

              bootbox.alert({
                className: "bootbox-modal",
                centerVertical: true,
                backdrop: true,
                message: `<div class="icon"><i class="mdi mdi-check-circle text-success"></i></div>
                          <h3 class="title">Inscription réussie&nbsp;!</h3>
                          <p class="text mb-1">Vous recevrez régulièrement nos actualités et nos conseils. Vous pouvez vous désinscrire à tout moment.</p>`,
                closeButton: false,
                buttons: {
                  ok: {
                    label: "OK",
                    className: "btn-secondary px-5",
                  },
                },
              });
            },
            error: (res) => {
              $subscriptionFormSubmitBtn
                .removeClass("loading")
                .attr("disabled", false);
              toastr.error(
                "Une erreur s'est produite, votre inscription a échoué"
              );
            },
          });
        },
        invalidHandler: (event, validator) => {
          $subscriptionFormSubmitBtn
            .removeClass("loading")
            .attr("disabled", false);
        },
      });

      $subscriptionForm
        .find("[name='subscription[email]']")
        .on("keypress", (event) => {
          const keycode = event.keyCode ? event.keyCode : event.which;
          if (keycode == "13") {
            event.preventDefault();
          }
        });
    };

    buildSubscriptionForm();
  }
});
