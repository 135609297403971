import lightGallery from "lightgallery";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";

$(document).on("turbolinks:load", () => {
  if (
    $("body").hasClass("developments") &&
    $("body").hasClass("show") &&
    $("body").hasClass("private")
  ) {
    // Stock
    $(".section-stock .detailsBtn").on("click", (e) => {
      e.preventDefault();
      const pid = $(e.currentTarget).data("pid");
      $(`.modal#unit-modal-${pid}`).modal("show");
    });

    // Unit Modal
    $(".modal.unit-modal").each(function (index) {
      const $modal = $(this);
      const pid = $modal.data("pid");

      lightGallery(document.getElementById(`lg-plans-${pid}`), {
        plugins: [lgZoom, lgThumbnail],
        mode: "lg-slide",
        speed: 500,
        loop: false,
        slideEndAnimation: false,
      });

      $modal.find(".openPlans").on("click", function (e) {
        e.preventDefault();
        const id = $(this).data("pid");
        $modal.find(`#lg-plans-${id} img:first`).trigger("click");
      });
    });
  }
});
